<template>
  <div class="detail-div">
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <table class="table table-bordered">
        <tr>
          <th>{{ $lang.college.form.logo }}</th>
          <td><img alt="Company Logo" @click="open(colleges.logo)" :src="colleges.logo" class="rounded-circle" height="auto" width="50%"/></td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.name }}</th>
          <td>{{ colleges.name }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.website }}</th>
          <td>{{ colleges.website }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.university }}</th>
          <td>{{ colleges.university }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.address1 }}</th>
          <td>{{ colleges.address1 }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.city }}</th>
          <td>{{ colleges.city }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.country }}</th>
          <td>{{ colleges.country }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.state }}</th>
          <td>{{ colleges.state }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.email }}</th>
          <td>{{ colleges.email }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.countryCode }}</th>
          <td>{{ colleges.countryCode }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.latitude }}</th>
          <td>{{ colleges.latitude }}</td>
        </tr>
        <tr>
          <th>{{ $lang.college.form.longitude }}</th>
          <td>{{ colleges.longitude }}</td>
        </tr>
      </table>

      <!--Start -->
      <CRow>
        <CCol md="12">
          <h4 class="detail-heading"><strong>{{ $lang.profile.heading.adminUser }}</strong></h4>
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="3">
          <CRow>
            <CCol md="12" class="text-center">
              <img alt="User Profile Picture" @click="open(user.avatar)" :src="user.avatar" class="rounded-circle" height="auto" width="50%"/>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="9" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="4" v-show="user.name">
              <p class="custome-labal">{{ $lang.profile.user.name }}</p>
              <p class="custome-detail">{{ user.name }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.title">
              <p class="custome-labal">{{ $lang.profile.user.title }}</p>
              <p class="custome-detail">{{ user.title }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.email">
              <p class="custome-labal">{{ $lang.profile.user.email }}</p>
              <p class="custome-detail">{{ user.email }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.country">
              <p class="custome-labal">{{ $lang.profile.user.country }}</p>
              <p class="custome-detail">{{ user.country }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.state">
              <p class="custome-labal">{{ $lang.profile.user.state }}</p>
              <p class="custome-detail">{{ user.state }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.city">
              <p class="custome-labal">{{ $lang.profile.user.city }}</p>
              <p class="custome-detail">{{ user.city }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.address1">
              <p class="custome-labal">{{ $lang.profile.user.address1 }}</p>
              <p class="custome-detail">{{ user.address1 }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="user.role">
              <p class="custome-labal">{{ $lang.profile.user.role }}</p>
              <p class="custome-detail">{{ user.role }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="displayUserData.status">
              <p class="custome-labal">{{ $lang.profile.user.status }}</p>
              <p class="custome-detail">
                <CBadge :color="statusBudget(displayUserData.status)">{{ displayUserData.status }}</CBadge>
              </p>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CButton color="danger" size="sm" @click="cancel">
        <CIcon name="cil-ban"/>
        {{ $lang.buttons.general.crud.cancel }}
      </CButton>
    </CCol>
  </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import { college } from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import moment from "moment";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";
import {serverBus} from "../../../main";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "CollegeView",
  mixins: [Mixin],
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      amountField: "",
      viewImage: "",
      largeModal: false,
      persontageField: "",
      userField: "",
      companyField: "",
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: college,
      userOptions: [],
      displayUserData : [],
      editId: this.$route.params.id,
      colleges: {
        address1 : "",
        city:"",
        country:"",
        state:"",
        email:"",
        countryCode:"",
        latitude:"",
        longitude:"",
        logo:"",
        website:"",
        user:"",
        university:"",
        name:"",
      },
      user: "",
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios
        .get('/college/'+self.editId)
        .then((response) => {
          let college = response.data.data;
          self.colleges.address1 = college.address1;
          self.colleges.city = college.city.name;
          self.colleges.country = college.country.name;
          self.colleges.state = college.state.name;
          self.colleges.email = college.email;
          self.colleges.countryCode = college.countryCode;
          self.colleges.latitude = college.latitude;
          self.colleges.longitude = college.longitude;
          self.colleges.logo = college.logo;
          self.colleges.website = college.website;
          self.colleges.name = college.name;
          self.user = college.user;
          self.colleges.university = college.university.name;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    open(url) {
      let self = this;
      self.viewImage = url;
      self.largeModal = true;
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/college"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
